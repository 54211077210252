import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const distributors = [
  { name: 'Allah Ho Gas', location: 'Ajnianwala', phone: '0303-7333551' },
  { name: 'Hafiz Gas', location: 'Ajnianwala', phone: '0341-4808005' },
  { name: 'Sabir Gas', location: 'Ajnianwala', phone: '0342-7366676' },
  { name: 'Rana Gas', location: 'Khanqah Dogran', phone: '0305-1113815' },
  { name: 'Saif Gas', location: 'Gajiana', phone: '0300-4848825' },
  { name: 'Afridi Gas', location: 'Farooqabad', phone: '0301-8408270' }
];

function Distributors() {
  return (
    <Paper sx={{ p: 4, my: 5 }} id="distributors">
      <Typography
        variant="h3"
        textAlign={'center'}
        fontWeight={600}
        color={'#e3be2b'}
        mb={4}
      >
        Our Distributors
      </Typography>

      <Box sx={{ mx: { sm: 1, md: 6, lg: 8 } }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell align="right">Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {distributors.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default Distributors;
