import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from './pages/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Safety from './pages/Safety';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/safety',
    element: <Safety />
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
