import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import coverPhoto from '../static/images/cover.jpg';

function HeroSection() {
  return (
    <Grid item xs={12}>
      <Box
        id='home'
        sx={{
          background: `url(${coverPhoto})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography
          fontSize={40}
          fontWeight={600}
          color={'#fafafa'}
          textAlign={'center'}
        >
          Your{' '}
          <span style={{ fontWeight: '700', color: '#e3be2b' }}> Energy</span>{' '}
          Needs, Our Priority!
        </Typography>

        {/* <Typography
          fontSize={40}
          fontWeight={600}
          color={'white'}
          textAlign={'center'}
        >
          Omar Energy (pvt) Ltd.
        </Typography> */}
      </Box>
    </Grid>
  );
}

export default HeroSection;
