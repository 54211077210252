import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

const commitments = [
  {
    title: 'Premium Quality',
    description:
      'Our LPG meets the strictest quality standards. You can always rely on clean, efficient and eco-friendly LPG for your home or business.'
  },
  {
    title: 'Competitive Pricing',
    description:
      'We offer competitive pricing without compromising on safety or service excellence. At Omar Energy, you will always get the best value for your money.'
  },
  {
    title: 'Expert Staff',
    description:
      'Our team is well-trained and knowledeable, providing friendly assistance and professional advice to our valued customers. We are committed to delivering a positive and fulfilling experience for everyone.'
  },
  {
    title: 'Reliability',
    description:
      'With our proactive demand forecasting, we endeavor to have a sufficient supply to meet the needs consumers. We are committed to provide timely and reliable deliveries to our commercial customers.'
  }
];

function Commitments() {
  return (
    <Paper sx={{ p: 4, my: 5 }}>
      <Box>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid
            item
            xs={12}
            md={4}
            justifyContent={'center'}
            borderRight={{ md: 1 }}
          >
            <Typography
              variant='h3'
              fontWeight={600}
              mt={{ md: 10 }}
              color={'#e3be2b'}
            >
              Our Commitments
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {commitments.map((commitment, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <Typography variant='h6' my={1}>
                    <span style={{ color: '#e3be2b' }}>{i + 1}.</span>{' '}
                    {commitment.title}
                  </Typography>
                  <Typography variant='body1'>
                    {commitment.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default Commitments;
