import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

function Intro() {
  return (
    <Paper sx={{ p: 4, my: 5 }} id='about'>
      <Box>
        <Typography
          variant='h3'
          textAlign={'center'}
          fontWeight={600}
          color={'#e3be2b'}
        >
          Welcome to Omar Energy Private Limited
        </Typography>
        <Typography variant='h5' textAlign={'center'} my={1}>
          Your trusted partner for clean, efficient and reliable LPG supply.
        </Typography>
        <Typography variant='body1' textAlign={'center'} mt={2}>
          At Omar Energy, We take pride in being the leading LPG provider in the
          region, serving our customers with dedication and excellence. Our LPG
          is sourced from reputable local and international suppliers, ensuring
          it meets the strictest quality standards. We procure sizable quantity
          of LPG to ensure uninterrupted supply to domestic and commercial
          consumers. We adhere to strict safety protocols (under OGRA policies),
          ensuring that every step of the LPG handling process is carried out
          with utmost care and diligence.
        </Typography>
      </Box>
    </Paper>
  );
}

export default Intro;
