import { useState } from 'react';
import { TextField, Button, Typography, Box, Paper, Grid } from '@mui/material';

export default function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //
    setIsSubmitted(true);
    setName('');
    setEmail('');
    setMessage('');

    setTimeout(() => setIsSubmitted(false), 3000);
  };

  return (
    <Paper sx={{ p: 4, my: 5 }} id="contact">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
          <Typography
            variant="h3"
            textAlign={'center'}
            fontWeight={600}
            color={'#e3be2b'}
            mb={4}
          >
            Contact Us
          </Typography>
          <Box>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Correspondence Address</Typography>
                <Typography variant="body">
                  Shop No. 3, Galla Mandi,
                  <br />
                  Khanqah Dogran,
                  <br />
                  District Sheikhupura
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Site Address</Typography>
                <Typography variant="body">
                  Kalsian Bhattian
                  <br />
                  Tehsil Safdarabad,
                  <br />
                  District Sheikhupura
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 2, mb: 4 }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Phone:{' '}
                  <span style={{ fontWeight: 'normal' }}>0300-8861979</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Email:{' '}
                  <span style={{ fontWeight: 'normal' }}>
                    info@omarenergy.com
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {!isSubmitted ? (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
                type="email"
              />
              <TextField
                fullWidth
                label="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                margin="normal"
                required
                multiline
                rows={4}
              />
              <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                Submit
              </Button>
            </form>
          ) : (
            <Typography variant="subtitle1">
              {' '}
              Submitted. We will get back to you.{' '}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
