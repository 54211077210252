import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

function SafetyIntro() {
  return (
    <Paper sx={{ p: 4, my: 5 }} id="about">
      <Box>
        <Typography
          variant="h3"
          textAlign={'center'}
          fontWeight={600}
          color={'#e3be2b'}
        >
          Safety at Omar Energy Private Limited
        </Typography>

        <Typography variant="body1" textAlign={'center'} mt={2}>
          At Omar Energy, safety is our top priority, and we are committed to
          ensuring a secure environment for our employees, customers, and the
          community. As an LPG filling plant and distributor, we adhere strictly
          to the guidelines and procedures set forth by the Oil and Gas
          Regulatory Authority (OGRA). Our dedicated team is trained to uphold
          the highest safety standards, implementing robust protocols to
          guarantee the safe handling, storage, and distribution of LPG. Your
          safety is our responsibility at Omar Energy.
        </Typography>
      </Box>
    </Paper>
  );
}

export default SafetyIntro;
