import React from 'react';
import Navbar from '../components/Navbar';
import { Container } from '@mui/material';
// import Footer from '../components/Footer';
import SafetyIntro from '../components/SafetyIntro';
import SafetyPhotos from '../components/SafetyPhotos';

export default function Safety() {
  return (
    <div className="App">
      {/* <Alert /> */}
      <Navbar />

      <Container maxWidth="lg">
        <SafetyIntro />
        <SafetyPhotos />
      </Container>
    </div>
  );
}
