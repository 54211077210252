import React from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Container from '@mui/material/Container';
import Intro from '../components/Intro';
import Commitments from '../components/Commitments';
import Plant from '../components/Plant';
// import Footer from '../components/Footer';
import ContactUs from '../components/ContactUs';
import Team from '../components/Team';
import Distributors from '../components/Distributors';
// import Alert from './components/Alert';

export default function Home() {
  return (
    <div className="App">
      {/* <Alert /> */}
      <Navbar />
      <HeroSection />
      <Container maxWidth="lg">
        <Intro />
        <Plant />
        <Commitments />
        <Team />
        <Distributors />
        <ContactUs />
      </Container>
      {/* <Footer /> */}
    </div>
  );
}
