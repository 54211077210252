import { Grid, Paper, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import safety1 from '../static/images/safety/1.jpg';
import safety2 from '../static/images/safety/2.jpg';
import safety3 from '../static/images/safety/3.jpg';
import safety4 from '../static/images/safety/4.jpg';
import safety5 from '../static/images/safety/5.jpg';
import safety6 from '../static/images/safety/6.jpg';
import safety7 from '../static/images/safety/7.jpg';
import safety8 from '../static/images/safety/8.jpg';

const images = [
  { src: safety1, title: 'Safety Briefing' },
  { src: safety2, title: 'Briefing' },
  { src: safety3, title: 'Controlled Fire' },
  { src: safety4, title: 'Controlled Fire' },
  { src: safety5, title: 'Extinguisher' },
  { src: safety6, title: 'Tank Sprinkler' },
  { src: safety7, title: 'Tank Sprinkler' },
  { src: safety8, title: 'Tank Sprinkler' }
];
const videos = [
  'https://youtu.be/17s6kXxpeSk',
  'https://youtu.be/Ke3zNx1d_0s',
  'https://youtu.be/2D9JRuczgX8'
];
function SafetyPhotos() {
  return (
    <Paper sx={{ p: 4, my: 5 }}>
      <Typography
        variant="h3"
        textAlign={'center'}
        fontWeight={600}
        color={'#e3be2b'}
        mb={4}
      >
        Safety Drill
      </Typography>

      <Grid container spacing={2} justifyContent={'center'}>
        {images.map((image, i) => (
          <Grid
            item
            key={i}
            xs={12}
            md={3}
            display={'flex'}
            justifyContent={'center'}
          >
            <img
              src={image.src}
              alt={image.title}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'cover'
              }}
            />
          </Grid>
        ))}
        {videos.map((vidUrl, i) => (
          <Grid item key={i} xs={12} display={'flex'} justifyContent={'center'}>
            <ReactPlayer url={vidUrl} muted />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default SafetyPhotos;
