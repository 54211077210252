import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

const directors = [
  { name: 'Imtiaz Ahmad Bhatti', title: 'CEO' },
  { name: 'Riaz Ahmad Bhatti' },
  { name: 'Yasir Kamal Bhatti' },
  { name: 'Shahzad Ahmad Bhatti' },
  { name: 'Abdul Wadood Ahmad' },
  { name: 'Shafqat Mehmood' }
];

function Team() {
  return (
    <Paper sx={{ p: 4, my: 5 }} id="directors">
      <Typography
        variant="h3"
        textAlign={'center'}
        fontWeight={600}
        color={'#e3be2b'}
        mb={4}
      >
        Our Directors
      </Typography>
      <Box>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {directors.map((d, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Typography variant="h6">
                {d.name} {d.title && `(${d.title})`}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

export default Team;
