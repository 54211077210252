import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import plant1 from '../static/images/plant1.jpg';
import plant2 from '../static/images/plant2.jpg';
import plant3 from '../static/images/plant3.jpg';
import plant4 from '../static/images/plant4.jpg';

const images = [
  { src: plant1, title: 'Tank' },
  { src: plant2, title: 'Decanting Station' },
  { src: plant3, title: 'Filling Shed' },
  { src: plant4, title: 'Filling Shed and Tanks' }
];

function Plant() {
  return (
    <Paper sx={{ p: 4, my: 5 }}>
      <Typography
        variant='h3'
        textAlign={'center'}
        fontWeight={600}
        color={'#e3be2b'}
        mb={4}
      >
        Our Plant
      </Typography>

      <Grid container spacing={2} justifyContent={'center'}>
        {images.map((image, i) => (
          <Grid
            item
            key={i}
            xs={12}
            md={3}
            display={'flex'}
            justifyContent={'center'}
          >
            <img
              src={image.src}
              alt={image.title}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'cover'
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default Plant;
